import { Component } from 'react';
import { connect } from 'react-redux';
import * as Constants from './../store/index';
import axios from "axios";

export var configAdmin= {
  "initialQuota": {
    type: "string",
    placeholder: "0 a infinito",
    definedText: "Saldo promocional"
  },
}

export var getPersonalizad=function(personalizad){

  personalizad.weight=Math.trunc(personalizad.weight);
  if (personalizad.weight < 1){
    personalizad.weight = 1;
  }
  personalizad.length=Math.trunc(personalizad.length);
  personalizad.height=Math.trunc(personalizad.height);
  personalizad.width=Math.trunc(personalizad.width);
  return personalizad;
}

export var isStagesShow=function(shop){
  /*if (shop && shop.length > 0 &&shop[0].id === "OEgNmnIB50jaAvMn63XH"){
    return true;
  }
  return false;*/
  return true;
};

export var isDev=function(){
  /*if (window.url_server === "https://dev.triciclogo.com/api/"){
    return true;
  }*/
  return false;
};

export var isShow=function(shop, type){
  if (type === "stages"){
    return isStagesShow(shop);
  }else if (type === "isSeeBalance"){
    return isSeeBalance();
  }
  return false;
};


export var isSeeBalance = () =>{
  //console.log(Constants.initialState.config.isSeeBalance);
  try{
    return Constants.initialState.config.isSeeBalance;
  }catch(e){
    console.log(e);
  }
  return false;
  
}

export var isCheckBalanceTransporter = (transp, transporter) =>{
  //console.log(Constants.initialState.config.isSeeBalance);
  try{
    if (transporter){
      if (transporter.hasOwnProperty(transp)){
        return transporter[transp];
      }
    }
  }catch(e){
    console.log(e);
  }
  return true;
  
}

export var checkSeeBalance = (idShop) => {
  const url = Constants.URL_API+"shippings/carriers/carrier-customer/?customerId="+idShop;
  axios.get(url, Constants.getHeaders()).then(response => response.data,error => {
      Constants.setErrors(error);
      return error;
    }).then((data) => {
      
      if (data.length > 0){
          let countActive = 0;
          let countCarrier = 0;
          let transporter = {};
          for (var i=0; i < data.length; i++) {
              var value= data[i];
              if (value.enabled){
                countActive++;
                transporter[value.carrierId]=true;
                if (Object.keys(value.carrierData).length > 0){
                  countCarrier++;
                  transporter[value.carrierId]=false;
                }
              }
          }

          let config = Constants.initialState.config;
          /*console.log(countActive);
          console.log(countCarrier);
          console.log(config.isSeeBalance);
          */
          
          if(countActive === countCarrier && config.isSeeBalance){
            config.isSeeBalance = false;
            localStorage.setItem(Constants.storage.CHANGE_CONFIG, JSON.stringify(config));
            window.location.reload();
          }else if (countActive !== countCarrier && !config.isSeeBalance){
            config.isSeeBalance = true;
            localStorage.setItem(Constants.storage.CHANGE_CONFIG, JSON.stringify(config));
            window.location.reload();
          }

          localStorage.setItem("transporter", JSON.stringify(transporter));

          console.log("Chequeo de ver balance:"+ config.isSeeBalance );
      }
  }); 

}

export var loadDataCarriers = (idShop, callback) => {

  let carriers = null;
  
  const url = Constants.URL_API+"shippings/carriers/carrier-customer/?customerId="+idShop;
  axios.get(url, Constants.getHeaders()).then(response => response.data,error => {
      Constants.setErrors(error);
      return error;
    }).then((data) => {
      
      if (data.length > 0){
          for (var i=0; i < data.length; i++) {
              var value= data[i];
              if (value.enabled && value.visible){
                if (carriers === null){
                  carriers =[];
                }
                carriers[value.carrierId] = value;
              }
          }
      }
      callback(carriers);
  }); 
}


export var printPage = function(timerPrint=500, timerBody=1500){

  setTimeout(function(){
      window.print();
  }.bind(this), timerPrint);

  setTimeout(function(){
    document.body.style.overflowY = "scroll";
  }.bind(this), timerBody);
  return "";
}

export var addScrollBody = function(timerBody=1500){
  setTimeout(function(){
    document.body.style.overflowY = "auto";
  }.bind(this), timerBody);
}

export var getShopInfo = function(shop, index, key){

  if(shop[index]){
    return shop[index][key];
  }
  return "";
}

export var getShop = function(shop, index){

  for (var i in shop) {
    let element = shop[i];
    if (element && element.id === index){
      return element;
    }
  }
  return null;
}

export var getListCustomerId = function(shop){

  let list_shop=[];
  for (var i in shop) {
    let element = shop[i];
    list_shop[element.id] = element;
  }
  return list_shop;
}

export var getDataShop = function(shop, list, index, key){
    try{
      if (index >= 0){
        return list[index][key];
      }else{
        return shop[key];
      }
    }catch (e){
      console.log(e);
    }
    return "";
}

export var shippings = function(orden, shop ,addressSelected, user, shippingAddress, packSelect, orderId, orderName, email, phone, insuredAmount, returnOrder, checked, callback, callbackError, partial, shippingsPartial, checkedName="") {
  packSelect.measureUnit="C";
  packSelect.weightUnit="K";
  let shippings = {};
  let phoneShop = getDataShop(shop, shop.addresses ,addressSelected, "phone");
  if (phoneShop == null || phoneShop === ""){
    phoneShop = "9999 99999";
  }

  if(phone == null || phone === ""){
    phone = phoneShop;
  }

  let emailClient = email;
  if(emailClient == null || emailClient === ""){
    emailClient = user.data.email;
  }


  let contactName = shop.contactName;
  if (checkedName === "FEDEX" || checkedName === "ESTAFETA" || checkedName === "DHL"){
    contactName = "TRICICLO CREATIVO SA DE CV";
  }

  let state =shippingAddress.provinceCode;
  if(checkedName === "T1ENVIOS_DHL" || checkedName === "T1ENVIOS_UPS" || checkedName === "T1ENVIOS_FEDEX"){
    state = shippingAddress.province;
  }

  phone= getPhone(phone);
  phoneShop= getPhone(phoneShop);
  
  if (!returnOrder){
      //Flujo normal
      shippings = {
          rateId: checked,
          orderId: orderId,
          customerId: orden.customerId,
          orderName: orderName,
          package: packSelect,
          insuredAmount: insuredAmount,
          destination: {
              firstName: shippingAddress.firstName,
              lastName: shippingAddress.lastName,
              email: emailClient,
              phone: phone,
              street1: shippingAddress.address1,
              street2: shippingAddress.address2,
              city: shippingAddress.city,
              state: state,
              country: shippingAddress.country,
              company: shippingAddress.company,
              reference: "",
              countryCode: shippingAddress.countryCode,
              zipCode: shippingAddress.zip
          },
          origin: {
              firstName: shop.titleGuide,
              lastName: contactName,
              email: user.data.email,
              phone: phoneShop,
              street1: getDataShop(shop, shop.addresses ,addressSelected, "street1"),
              street2: getDataShop(shop, shop.addresses ,addressSelected, "street2"),
              "number": "900",
              city: getDataShop(shop, shop.addresses ,addressSelected, "city"),
              state: getDataShop(shop, shop.addresses ,addressSelected, "state"),
              country: getDataShop(shop, shop.addresses ,addressSelected, "country"),
              countryCode: getDataShop(shop, shop.addresses ,addressSelected, "countryCode"),
              reference: getDataShop(shop, shop.addresses ,addressSelected, "reference"),
              zipCode: getDataShop(shop, shop.addresses ,addressSelected, "zip")
          }
      }
  }else{
      shippings = {
          rateId: checked,
          orderId: orden.id,
          orderName: orden.name,
          package: packSelect,
          insuredAmount: insuredAmount,
          origin: {
              firstName: shippingAddress.firstName,
              lastName: shippingAddress.lastName,
              email: email,
              phone: phone,
              street1: shippingAddress.address1,
              street2: shippingAddress.address2,
              city: shippingAddress.city,
              state: state,
              country: shippingAddress.country,
              reference: "",
              countryCode: shippingAddress.countryCode,
              zipCode: shippingAddress.zip
          },
          destination: {
              firstName: shop.titleGuide,
              lastName: contactName,
              email: user.data.email,
              phone:  phoneShop,
              street1: getDataShop(shop, shop.addresses ,addressSelected, "street1"),
              street2: getDataShop(shop, shop.addresses ,addressSelected, "street2"),
              "number": "900",
              city: getDataShop(shop, shop.addresses ,addressSelected, "city"),
              state: getDataShop(shop, shop.addresses ,addressSelected, "state"),
              country: getDataShop(shop, shop.addresses ,addressSelected, "country"),
              countryCode: getDataShop(shop, shop.addresses ,addressSelected, "countryCode"),
              reference: getDataShop(shop, shop.addresses ,addressSelected, "reference"),
              zipCode: getDataShop(shop, shop.addresses ,addressSelected, "zip")
          }
      }
  }

  if (partial){
    shippings.items = shippingsPartial;
  }
  const url = Constants.URL_API+"shippings";
  console.log(url);
  console.log(shippings);

  const timeout = setTimeout(() => {
    callbackError("No pudimos generar la guía, por favor intenta nuevamente en unos minutos");
  }, 45000);
  
  axios.post(url, shippings, Constants.getHeaders()).then(response => response.data,error => {
      Constants.setErrors(error);
      if (error.response && error.response.status === 400) {
          clearTimeout(timeout);
          callbackError(Constants.getMessage(error.response.data.message));
      }else if (error.response && error.response.status === 422) {
          let errors = JSON.parse(error.response.data.message);
          let textError = errors.errors.base[0];
          clearTimeout(timeout);
          callbackError(Constants.getMessage(textError), orderId);
      }
    
      return error;
    }).then((data) => {
      if (!data.message) {
        clearTimeout(timeout);
        callback(data);
      }    
  }); 
}

export var loadLayouts = function(dispatch, filter, callback) {
    const url =  Constants.URL_API+"shippings/layouts"+filter;
    Constants.scrollToWindow();
    axios.get(url, Constants.getHeaders()).then(response => response.data,error => {
        Constants.setErrors(error);
        return error;
      }).then((data) => {
        console.log(data);
        dispatch({
            type: 'CHANGE_LIST_EMPAQUES',
            list: data,
        });
        callback();
    }); 
}

export var editShop = function(shop, callback, callbackError) {
  const url = Constants.URL_API+"customers/"+shop.id;
  
  axios.put(url, shop, Constants.getHeaders()).then(response => response.data,error => {
      console.log(error.response);
      if (error.response.status === 401 || error.response.status === 400 || error.response.status === 403  || error.response.status === 404) {
        callbackError(Constants.getMessage(error.response.data.message));
      }
      return error;
    }).then((data) => {
        callback(data);
    });

}

export var loadDataUsers = function(dispatch, isGoHome) {
  const url = Constants.URL_API+"customers/users/logged";
    axios.get(url, Constants.getHeaders()).then(response => response.data,error => {
      Constants.setErrors(error);
      if (error.response.status === 400) {
        let retrievedObject = localStorage.getItem(Constants.storage.SHOP);
          console.log(retrievedObject);
          if (retrievedObject === '""' || retrievedObject === null){
            window.location.href = "/preferences";
          }
      }
      return error;
    })
    .then((data) => {
        console.log("customers/users/logged");
        console.log(data);
        dispatch({
          type: Constants.types.LOGIN_LOCAL,
          data: data
        });
        if (data === ""){
          console.log("Entro");
          let retrievedObject = localStorage.getItem(Constants.storage.SHOP);
          console.log(retrievedObject);
          if (retrievedObject === '""' || retrievedObject === null){
            window.location.href = "/preferences";
          }
        }else if (data.id){
          if (data.permissions && data.permissions[0] === "read:customers"){
            dispatch({
              type: Constants.types.LOGIN_TYPE,
              data: "ADMIN"
            });
            if (isGoHome){
              window.location.href = '/home';
            }
          }else{
            loadDataShop(dispatch, isGoHome);
          }
        }

    }).catch(error => {
      console.log(error);
    });
}

export var loadDataShop = function(dispatch, isGoHome) {
  const url = Constants.URL_API+"customers/loggeds";
  axios.get(url, Constants.getHeaders()).then(response => response.data,error => {
    Constants.setErrors(error);
    return error;
  })
  .then((data) => {
      console.log(data);
      if (data){
        dispatch({
          type: Constants.types.LOGIN_SHOP,
          data: data
        });
        dispatch({
          type: Constants.types.LOGIN_TYPE,
          data: data.type
        });
        if (isGoHome){
          window.location.href = '/home' ;
        }
      }
  }).catch(error => {
    console.log(error);
  });
}

export var validateMax = function(arrayList, max) {
  for (var i in arrayList) {
    let element = arrayList[i];
    if (element && element.length > max){
      return true;
    }
  }
  return false;
}

export var textSubString = function(text, min, max) {
  try{
    return text.substr(min, max);
  }catch(e){
    console.log(3);
    return text;
  }
}

export var getPhone = function(text) {
  if(text  && text != ""){
    text = text.replace(/ /g, '');
    text = text.replace(/\+/g, '');
    text = text.replace(/\(/g, '');
    text = text.replace(/\)/g, '');
  }
  return text;
}

class General extends Component {
}
      

export const getPhoneLalavone = (phone) => {
  let outputString = 0;
  if(phone){
    outputString = phone.replace(/[a-z A-Z :  -/()+-]+/g, "");
  }
  outputString = "000000000000055" + outputString;
  outputString = outputString.slice((outputString.length -10), outputString.length);
  return outputString;
};

const mapStateToProps = (state) => ({
    empaques: state.packageList,
});

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export var carriersDefault = {
  DHL_EXPRESS: {
      enabled: false,
      edit: false,
      isEdit: true
  },
  FEDEX: {
      enabled: false,
      edit: false,
      isEdit: false
  },
  ESTAFETA: {
      enabled: true,
      edit: false,
      isEdit: true
  },
  PAQUETEXPRESS: {
    enabled: false,
    edit: false,
    isEdit: true
  },
  REDPACK:{
    enabled: false,
    edit: false,
    isEdit: false
  },
  ODM:{
    enabled: false,
    edit: false,
    isEdit: false
  },
  TREGGO:{
    enabled: false,
    edit: false,
    isEdit: false
  },
  MERQ:{
    enabled: false,
    edit: false,
    isEdit: false
  },
  SHIPANDGO:{
    enabled: false,
    edit: false,
    isEdit: false
  },
  T1ENVIOS_DHL:{
    enabled: true,
    edit: false,
    isEdit: false
  },
  T1ENVIOS_UPS:{
    enabled: false,
    edit: false,
    isEdit: false
  },
  T1ENVIOS_FEDEX:{
    enabled: true,
    edit: false,
    isEdit: false
  },
  T1ENVIOS_99MIN:{
    enabled: false,
    edit: false,
    isEdit: false
  }
};

export const CARTAPORTE = {
  "IN_PROGRESS": "En progreso", 
  "CREATED": "Creada",
  "ERROR": "En progreso.",
};

export const CARRIERS = {
  "DHL_EXPRESS": "DHL_EXPRESS", 
  "FEDEX": "FEDEX",
  "ESTAFETA": "ESTAFETA",
  "REDPACK": "REDPACK",
  "ODM": "ODM",
  "TREGGO": "TREGGO",
  "SHIPANDGO": "SHIPANDGO",
  "T1ENVIOS_DHL": "T1ENVIOS_DHL",
  "T1ENVIOS_UPS": "T1ENVIOS_UPS",
  "T1ENVIOS_FEDEX": "T1ENVIOS_FEDEX",
  "T1ENVIOS_99MIN": "T1ENVIOS_99MIN",
};

export const CARRIERS_FILTER = {
  "0": "Seleccione una transportadora", 
  "DHL_EXPRESS": "DHL_EXPRESS", 
  "FEDEX": "FEDEX",
  "ESTAFETA": "ESTAFETA",
  "REDPACK": "REDPACK",
  "LALAMOVE": "LALAMOVE",
  "PAQUETEXPRESS": "PAQUETEXPRESS",
  "ODM": "ODM",
  "TREGGO": "TREGGO",
  "SHIPANDGO": "SHIPANDGO",
  "T1ENVIOS_DHL": "T1ENVIOS_DHL",
  "T1ENVIOS_UPS": "T1ENVIOS_UPS",
  "T1ENVIOS_FEDEX": "T1ENVIOS_FEDEX",
  "T1ENVIOS_99MIN": "T1ENVIOS_99MIN",
};

/*

,
  "T1ENVIOS_DHL": {
    name: "DHL",
    img: "dhl-logo.png",
    color: "#FBCA06",
    url: "https://www.dhl.com/mx-es/home/tracking/tracking-express.html?submit=1&tracking-id=",
    isConfigurable: true,
    isActive: false,
    isPickup: true,
    list:{
      "EXPRESS DOMESTIC": [{}], 
      "ECONOMY SELECT DOMESTIC": [{}],
    },
    config:{},
    configAdmin:{},
  }
"DHL_EXPRESS_ECONOMY": [{}], 
      "DHL_EXPRESS_EXPRESS": [{}],



      "REDPACK_EXPRESS": [{}],
      "REDPACK_ECOEXPRESS": [{}]    
      
      "REDPACK_ECOEXPRESS": [{}]     


      ,
      "METROPOLITANO": [{}]
     */

export const CARRIERS_INFO = {
  "DHL_EXPRESS": {
    name: "DHL",
    img: "dhl-logo.png",
    color: "#FBCA06",
    url: "https://www.dhl.com/mx-es/home/tracking/tracking-express.html?submit=1&tracking-id=",
    isConfigurable: true,
    isActive: false,
    isPickup: true,
    list:{
      "DHL_EXPRESS_ECONOMY": [{}], 
      "DHL_EXPRESS_EXPRESS": [{}],
    },
    config:{
      "site-id": {
        type: "string",
        placeholder: "v62_XXXXXX"
      },
      "password": {
        type: "string",
        placeholder: "kWXXXXXXX"
      },
      "account": {
        type: "string",
        placeholder: "980XXXXX"
      }
    },
    configAdmin:{},
    maxStreet: 35,
  }, 
  "FEDEX": {
    name: "Fedex",
    img: "fedex-logo.png",
    color: "#fff",
    url:"https://www.fedex.com/apps/fedextrack/index.html?cntry_code=mx&tracknumbers=",
    isConfigurable: true,
    isActive: true,
    isPickup: false,
    list:{
      "FEDEX_SAVER": [{}],
      "FEDEX_STANDARD": [{}],
    },
    config:{
      "userKey": {
        type: "string",
        placeholder: "v62_XXXXXX"
      },
      "userPassword": {
        type: "string",
        placeholder: "kWXXXXXXX"
      },
      "parentKey": {
        type: "string",
        placeholder: "980XXXXX"
      }
      ,
      "parentPassword": {
        type: "string",
        placeholder: "980XXXXX"
      }
      ,
      "accountNumber": {
        type: "string",
        placeholder: "980XXXXX"
      }
      ,
      "meterNumber": {
        type: "string",
        placeholder: "980XXXXX"
      }
    },
    configAdmin:{}
  }, 
  "LALAMOVE": {
    name: "Lalamove",
    img: "lalamove-logo.png",
    color: "#fff",
    url:"",
    fixedCommission: 15,
    percentageCommission: 0,
    isConfigurable: false,
    list:{},
    configAdmin:{}
  }
  , 
  "ESTAFETA": {
    name: "Estafeta",
    img: "estafeta-logo.png",
    color: "#fff",
    url:"https://cs.estafeta.com/es/Tracking/searchByGet?wayBillType=0&isShipmentDetail=False&wayBill=",
    isConfigurable: true,
    isActive: false,
    isPickup: true,
    list:{
      "ESTAFETA_TERRESTRE": [{}],
      "ESTAFETA_DIA_SIG": [{}],
      "METROPOLITANO": [{}]
    },
    config:{
      "cotizador.idusuario": {
        type: "string",
        placeholder: "1"
      },
      "cotizador.usuario": {
        type: "string",
        placeholder: "ADXXXXXXX"
      },
      "cotizador.contra": {
        type: "string",
        placeholder: "980XXXXX"
      }
      ,
      "label.login": {
        type: "string",
        placeholder: "pruXXXXX"
      },
      "label.password": {
        type: "string",
        placeholder: "980XXXXX"
      }
      ,
      "label.suscriberid": {
        type: "string",
        placeholder: "980XXXXX"
      }
      ,
      "label.customernumber": {
        type: "string",
        placeholder: "25XXXXX"
      },
      "label.officenum": {
        type: "string",
        placeholder: "980XXXXX"
      },
      "label.apikey": {
        type: "string",
        placeholder: "86XXXX"
      },
      "label.apisecret": {
        type: "string",
        placeholder: "86XXXX"
      },
      "tracking.idusuario": {
        type: "string",
        placeholder: "980XXXXX"
      }
      ,
      "tracking.usuario": {
        type: "string",
        placeholder: "980XXXXX"
      }
      ,
      "tracking.contra": {
        type: "string",
        placeholder: "980XXXXX"
      },
      "pickup.apikey": {
        type: "string",
        placeholder: "l7xxXXXXXXXXXXc148b99faf25acc7a8ab25"
      },
      "pickup.accountnumber": {
        type: "string",
        placeholder: "86XXXX"
      },
      "prepago": {
        type: "string",
        placeholder: "Poner SI para prepago, dejar vacio para no"
      }
      
    },
    configAdmin:{},
    maxStreet: 60,
  }
  , 
  "PAQUETEXPRESS": {
    name: "PaquetExpress",
    img: "paquetexpress-logo.png",
    color: "#fff",
    url:"https://www.paquetexpress.com.mx/?",
    isConfigurable: true,
    isActive: true,
    isPickup: true,
    urlIframe: "https://www.autososcar.com.ar/paquetexpress/?tracking=",
    list:{
      "PAQUETEXPRESS_ST": [{}]    
    },
    config:{
      "billclntid": {
        type: "string",
        placeholder: "6800000"
      },
      "cotizador.user": {
        type: "string",
        placeholder: "WS_XXXXX"
      },
      "cotizador.password": {
        type: "string",
        placeholder: "XXXXX"
      },
      "cotizador.token": {
        type: "string",
        placeholder: "BA8E31000000000000000000000"
      }, 
      "token.user": {
        type: "string",
        placeholder: "WS_XXXXX"
      },
      "token.password": {
        type: "string",
        placeholder: "XXXXX"
      }
    },
    configAdmin:{}
  }
  , 
  "REDPACK": {
    name: "RedPack",
    img: "redpack-logo.png",
    color: "#fff",
    isPickup: true,
    url:"https://www.redpack.com.mx/rastreo-de-envios/",
    isConfigurable: true,
    isActive: true,
    list:{
    },
    config:{},
    configAdmin:{
      "redpack.folio.express.min": {
        type: "string",
        placeholder: "08289XXXX"
      },
      "redpack.folio.express.max": {
        type: "string",
        placeholder: "08289XXXX"
      },
      "redpack.folio.express.current": {
        type: "string",
        placeholder: "08289XXXX"
      },
      "redpack.folio.ecoexpress.min": {
        type: "string",
        placeholder: "08289XXXX"
      },
      "redpack.folio.ecoexpress.max": {
        type: "string",
        placeholder: "08289XXXX"
      },
      "redpack.folio.ecoexpress.current": {
        type: "string",
        placeholder: "08289XXXX"
      },
    }
  }, 
  "ODM": {
    name: "ODM Express",
    img: "odm.png",
    color: "#fff",
    isPickup: true,
    url:"https://odmexpress.com.mx/rastreo-2/?rastreo_fall=",
    isConfigurable: true,
    isActive: true,
    list:{
      "ODM": [{}],    
    },
    config:{
      "odm.user": {
        type: "string",
        placeholder: "XXX"
      },
      "odm.password": {
        type: "string",
        placeholder: "XXXX"
      }
    },
    configAdmin:{
    }
  }, 
  "TREGGO": {
    name: "TREGGO",
    img: "treggo.jpg",
    color: "#fff",
    isPickup: true,
    url:"https://mx.treggo.co/viaje/seguimiento.html?destino=",
    isConfigurable: true,
    isActive: true,
    list:{
      "TREGGO": [{}],    
    },
    config:{},
    configAdmin:{
    }
  }, 
  "MERQ": {
    name: "MERQ",
    img: "MERQ.png",
    color: "#fff",
    isPickup: true,
    url:"https://merq.com.mx/rastrear-paquete/",
    isConfigurable: true,
    isActive: true,
    list:{
      "MERQ": [{}],    
    },
    config:{},
    configAdmin:{
    }
  }, 
  "SHIPANDGO": {
    name: "SHIPANDGO",
    name_show: "DHL",
    img: "dhl-logo.png",
    color: "#fff",
    isPickup: true,
    url:"https://www.shipandgo.com.mx/",
    isConfigurable: true,
    isActive: true,
    list:{
      "N": [{}]
    },
    config:{},
    configAdmin:{
    }
  },
  "T1ENVIOS_DHL": {
    name: "T1ENVIOS_DHL",
    name_show: "DHL",
    img: "dhl-logo.png",
    color: "#FBCA06",
    url: "https://www.dhl.com/mx-es/home/tracking/tracking-express.html?submit=1&tracking-id=",
    isConfigurable: true,
    isActive: false,
    isPickup: false,
    list:{
      "EXPRESS DOMESTIC": [{}], 
      "ECONOMY SELECT DOMESTIC": [{}],
    },
    config:{},
    configAdmin:{},
  },
  "T1ENVIOS_UPS": {
    name: "T1ENVIOS_UPS",
    name_show: "UPS",
    img: "ups-logo-0.png",
    color: "#FBCA06",
    url: "https://www.ups.com/track?loc=es_MX&requester=ST/",
    isConfigurable: true,
    isActive: false,
    isPickup: false,
    list:{
      "UPS_SAVER": [{}], 
      "UPS_STANDAR": [{}],
    },
    config:{},
    configAdmin:{},
  },
  "T1ENVIOS_FEDEX": {
    name: "T1ENVIOS_FEDEX",
    name_show: "FEDEX",
    img: "fedex-logo.png",
    color: "#fff",
    url:"https://www.fedex.com/apps/fedextrack/index.html?cntry_code=mx&tracknumbers=",
    isConfigurable: true,
    isActive: false,
    isPickup: false,
    list:{
      "STANDARD_OVERNIGHT": [{}], 
      "FEDEX_EXPRESS_SAVER": [{}],
    },
    config:{},
    configAdmin:{},
  },
  "T1ENVIOS_99MIN": {
    name: "T1ENVIOS_99MIN",
    name_show: "99 Minutos",
    img: "99.jpg",
    color: "#fff",
    url:"https://tracking.99minutos.com/",
    isConfigurable: true,
    isActive: false,
    isPickup: false,
    list:{
      "SameDay": [{}], 
      "NextDay": [{}],
    },
    config:{},
    configAdmin:{},
  }
};

export const CARRIERS_RATE = {
  "DHL_EXPRESS_ECONOMY": {
    id: "DHL_EXPRESS_ECONOMY",
    carrierId: "DHL_EXPRESS",
    deliveryType: "ECONOMY SELECT DOMESTIC",
    name: "DHL economy",
    img: "dhl-logo.png",
    color: "#FBCA06",
    url: "https://www.dhl.com/mx-es/home/tracking/tracking-express.html?submit=1&tracking-id="
  }, 
  "DHL_EXPRESS_EXPRESS": {
    id: "DHL_EXPRESS_EXPRESS",
    carrierId: "DHL_EXPRESS",
    deliveryType: "EXPRESS DOMESTIC",
    name: "DHL express domestic",
    img: "dhl-logo.png",
    color: "#FBCA06",
    url: "https://www.dhl.com/mx-es/home/tracking/tracking-express.html?submit=1&tracking-id="
  },
  "FEDEX_SAVER": {
    id: "FEDEX_SAVER",
    carrierId: "FEDEX",
    deliveryType: "FEDEX_EXPRESS_SAVER",
    name: "FedEx nacional económico",
    img: "fedex-logo.png",
    color: "#fff",
    url:"https://www.fedex.com/apps/fedextrack/index.html?cntry_code=mx&tracknumbers="
  },
  "FEDEX_STANDARD": {
    id: "FEDEX_STANDARD",
    carrierId: "FEDEX",
    deliveryType: "STANDARD_OVERNIGHT",
    name: "FedEx nacional día siguiente",
    img: "fedex-logo.png",
    color: "#fff",
    url:"https://www.fedex.com/apps/fedextrack/index.html?cntry_code=mx&tracknumbers="
  },
  
  "ESTAFETA_TERRESTRE": {
    id: "ESTAFETA_TERRESTRE",
    carrierId: "ESTAFETA",
    deliveryType: "TERRESTRE",
    name: "Estafeta terrestre",
    img: "estafeta-logo.png",
    color: "#fff",
    url:"https://cs.estafeta.com/es/Tracking/searchByGet?wayBillType=0&isShipmentDetail=False&wayBill="
  },
  "ESTAFETA_DIA_SIG": {
    id: "ESTAFETA_DIA_SIG",
    carrierId: "ESTAFETA",
    deliveryType: "DIA_SIG",
    name: "Estafeta día siguiente",
    img: "estafeta-logo.png",
    color: "#fff",
    url:"https://cs.estafeta.com/es/Tracking/searchByGet?wayBillType=0&isShipmentDetail=False&wayBill="
  },
  "METROPOLITANO": {
    id: "METROPOLITANO",
    carrierId: "ESTAFETA",
    deliveryType: "METROPOLITANO",
    name: "Estafeta METROPOLITANO",
    img: "estafeta-logo.png",
    color: "#fff",
    url:"https://cs.estafeta.com/es/Tracking/searchByGet?wayBillType=0&isShipmentDetail=False&wayBill="
  },
  "DIA_SIG_PREPAGO": {
    id: "DIA_SIG_PREPAGO",
    carrierId: "ESTAFETA",
    deliveryType: "DIA_SIG_PREPAGO",
    name: "Estafeta día siguiente",
    img: "estafeta-logo.png",
    color: "#fff",
    url:"https://cs.estafeta.com/es/Tracking/searchByGet?wayBillType=0&isShipmentDetail=False&wayBill="
  },
  "TERRESTRE_PREPAGO": {
    id: "TERRESTRE_PREPAGO",
    carrierId: "ESTAFETA",
    deliveryType: "TERRESTRE_PREPAGO",
    name: "Estafeta prepago",
    img: "estafeta-logo.png",
    color: "#fff",
    url:"https://cs.estafeta.com/es/Tracking/searchByGet?wayBillType=0&isShipmentDetail=False&wayBill="
  },
  "DOS_DIAS": {
    id: "DOS_DIAS",
    carrierId: "ESTAFETA",
    deliveryType: "DOS_DIAS",
    name: "Estafeta dos días",
    img: "estafeta-logo.png",
    isOnlyCredentials: true,
    color: "#fff",
    url:"https://cs.estafeta.com/es/Tracking/searchByGet?wayBillType=0&isShipmentDetail=False&wayBill="
  },
  "PAQUETEXPRESS_ST": {
    id: "PAQUETEXPRESS_ST",
    carrierId: "PAQUETEXPRESS",
    deliveryType: "ST",
    name: "PaquetExpress Standard",
    img: "paquetexpress-logo.png",
    color: "#fff",
    url:"https://cs.estafeta.com/es/Tracking/searchByGet?wayBillType=0&isShipmentDetail=False&wayBill="
  },
  "REDPACK_EXPRESS": {
    id: "REDPACK_EXPRESS",
    carrierId: "REDPACK",
    deliveryType: "EXPRESS",
    name: "RedPack express",
    img: "redpack-logo.png",
    color: "#fff",
    url:"https://www.redpack.com.mx/es/rastreo/?guias="
  },
  "REDPACK_ECOEXPRESS": {
    id: "REDPACK_ECOEXPRESS",
    carrierId: "REDPACK",
    deliveryType: "ECOEXPRESS",
    name: "RedPack eco express",
    img: "redpack-logo.png",
    color: "#fff",
    url:"https://www.redpack.com.mx/es/rastreo/?guias="
  },
  "ODM": {
    id: "ODM",
    carrierId: "ODM",
    deliveryType: "ODM",
    name: "ODM Express",
    img: "odm.png",
    color: "#fff",
    url:"https://odmexpress.com.mx/rastreo-2/?rastreo_fall="
  },
  "TREGGO": {
    id: "TREGGO",
    carrierId: "TREGGO",
    deliveryType: "TREGGO",
    name: "TREGGO",
    img: "treggo.jpg",
    color: "#fff",
    url:"https://odmexpress.com.mx/rastreo-2/?rastreo_fall="
  },
  "MERQ": {
    id: "MERQ",
    carrierId: "MERQ",
    deliveryType: "MERQ",
    name: "MERQ",
    img: "MERQ.png",
    color: "#fff",
    url:"https://merq.com.mx/rastrear-paquete/"
  },
  "G": {
    id: "G",
    carrierId: "SHIPANDGO",
    carrierName: "DHL",
    deliveryType: "G",
    deliveryTypeName: "ECONOMY SELECT DOMESTIC",
    name: "G",
    img: "dhl-logo.png",
    color: "#fff",
    url:"https://www.shipandgo.com.mx/"
  },
  "N": {
    id: "N",
    carrierId: "SHIPANDGO",
    carrierName: "DHL",
    deliveryType: "N",
    deliveryTypeName: "EXPRESS DOMESTIC",
    name: "N",
    img: "dhl-logo.png",
    color: "#fff",
    url:"https://www.shipandgo.com.mx/"
  },
  "EXPRESS DOMESTIC": {
    id: "EXPRESS DOMESTIC",
    carrierName: "DHL",
    carrierId: "T1ENVIOS_DHL",
    deliveryType: "EXPRESS DOMESTIC",
    name: "DHL economy",
    img: "dhl-logo.png",
    color: "#FBCA06",
    url: "https://www.dhl.com/mx-es/home/tracking/tracking-express.html?submit=1&tracking-id="
  }, 
  "ECONOMY SELECT DOMESTIC": {
    id: "ECONOMY SELECT DOMESTIC",
    carrierName: "DHL",
    carrierId: "T1ENVIOS_DHL",
    deliveryType: "ECONOMY SELECT DOMESTIC",
    name: "DHL economy",
    img: "dhl-logo.png",
    color: "#FBCA06",
    url: "https://www.dhl.com/mx-es/home/tracking/tracking-express.html?submit=1&tracking-id="
  }, 
  "UPS_STANDAR": {
    id: "UPS_STANDAR",
    carrierName: "UPS",
    carrierId: "T1ENVIOS_UPS",
    deliveryType: "UPS_STANDAR",
    name: "UPS Standar",
    img: "ups-logo-0.png",
    color: "#FBCA06",
    url: "https://www.ups.com/track?loc=es_MX&requester=ST/"
  }, 
  "UPS_SAVER": {
    id: "UPS_SAVER",
    carrierName: "UPS",
    carrierId: "T1ENVIOS_UPS",
    deliveryType: "UPS_SAVER",
    name: "UPS Saver",
    img: "ups-logo-0.png",
    color: "#FBCA06",
    url: "https://www.ups.com/track?loc=es_MX&requester=ST/"
  }, 
  "STANDARD_OVERNIGHT": {
    id: "STANDARD_OVERNIGHT",
    carrierName: "FEDEX",
    carrierId: "T1ENVIOS_FEDEX",
    deliveryType: "STANDARD_OVERNIGHT",
    name: "Nacional día siguiente",
    img: "fedex-logo.png",
    color: "#fff",
    url: "https://www.fedex.com/apps/fedextrack/index.html?cntry_code=mx&tracknumbers="
  }, 
  "FEDEX_EXPRESS_SAVER": {
    id: "FEDEX_EXPRESS_SAVER",
    carrierName: "FEDEX",
    carrierId: "T1ENVIOS_FEDEX",
    deliveryType: "FEDEX_EXPRESS_SAVER",
    name: "Nacional económico",
    img: "fedex-logo.png",
    color: "#fff",
    url: "https://www.fedex.com/apps/fedextrack/index.html?cntry_code=mx&tracknumbers="
  }, 
  "SameDay": {
    id: "SameDay",
    carrierName: "99 Minutos",
    carrierId: "T1ENVIOS_99MIN",
    deliveryType: "SameDay",
    name: "Mismo día",
    img: "99.jpg",
    color: "#fff",
    url: "https://tracking.99minutos.com/"
  }, 
  "NextDay": {
    id: "NextDay",
    carrierName: "99 Minutos",
    carrierId: "99.jpg",
    deliveryType: "NextDay",
    name: "Siguiente día",
    img: "99.jpg",
    color: "#fff",
    url: "https://tracking.99minutos.com/"
  }
};

export const LALAMOVE = {
  "MOTORCYCLE": "Moto (40 × 40 × 35 cm, 20 kg)", 
  "MPV": "Vehículo Utilitario SUV (125 x 80 x 60 cm, 200 kg)",
  "UV_FIORINO": "Minivan/Camioneta (188 x 133 x 108 cm, 500 kg)",
  "TRUCK330": "Camión (200 x 200 x 170 cm, 1,000 kg)"
};

export const yesNo = {
  "true": "Si", 
  "false": "No"
};

export const pickupOptions = {
  "true": "Solicitar Recolección", 
  "false": "Dejar en sucursal"
};

export const pickupOptionsPreference = {
  "true": "Solicitar Recolección", 
  "false": "Sin recolección / Dejar en sucursal"
};

export const pagination = {
    10: "10", 
    15: "15",
    30: "30", 
    50: "50",
    100: "100",
    150: "150",
    200: "200"
};

export const STATE_RETURNS = {
  PENDING:"Pendiente", 
  APPROVED: "Aprobada", 
  REJECTED: "Rechazada",
}

export const STATE_RETURNSRECEIVED = {
  APPROVED: "Aprobada", 
  RECEIVED_ACCEPTED: "Recibida y aceptada", 
  RECEIVED_REJECTED: "Recibido y rechazado",
}

export const icons = [
  "001-contorno-de-caja-de-carga-con-etiqueta.png","002-caja.png","003-suministros.png","004-cajas.png","005-paquete.png","006-paquete-1.png","007-caja-1.png","008-camion.png","009-enviado.png","010-logistica.png","011-transportadora.png","012-paquete-2.png","013-paquete-de-comida.png","014-paquete-3.png","015-paquete-4.png","016-paquete-o-empaquetar.png","017-paquete-5.png","018-paquete-6.png","019-peso-del-paquete.png","020-paquete-7.png","021-paquete-de-comida-1.png","022-paquete-8.png","023-paquete-o-empaquetar-1.png","024-paquete-de-seis.png","025-medicamento.png","026-transporte.png","027-transporte-1.png","028-transportador.png","029-transporte-de-paquetes-logisticos-en-cinta-transportadora.png","030-transporte-2.png","031-transporte-3.png","032-cinta-transportadora.png","033-transporte-4.png","034-transportador-1.png","035-cinta-transportadora-1.png","036-transportador-2.png","037-transportador-3.png","038-transporte-5.png","039-sin-bolsas-de-plastico.png","040-sin-ubicacion.png","041-sin-bolsas-de-plastico-1.png","1%20Box.png","10%20Box.png","11%20Box.png","12%20Box12.png","13%20Box13.png","14%20CUSTOMER%20SERVICE.png","15%20CUSTOMER%20SERVICE.png","16%20Delivery.png","17%20Delivery.png","18%20DeliveryMan.png","19%20DeliveryMan.png","2%20Box.png","20%20DeliveryMan.png","21%20DeliveryMan.png","22%20DeliveryMan.png","23%20Envelope.png","24%20LOCATION.png","25%20LOCATION.png","26%20Moto.png","27%20Moto.png","28%20PickPack.png","29%20PickPack.png","3%20Box.png","30%20PickPack.png","31%20PickPack.png","32%20PickPack.png","33%20PickPack.png","34%20Route.png","35%20Route.png","36%20Route.png","37%20Schedule.png","38%20Schedule.png","39%20Schedule.png","4%20Box.png","40%20Schedule.png","41%20Trolley.png","42%20Trolley.png","43%20Trolley.png","44%20Trolley.png","45%20Truck.png","46%20Truck.png","47%20Truck.png","5%20Box.png","6%20Box.png","7%20Box.png","8%20Box.png","9%20Box.png","no-photo.png",
];

export const COUNTRY = {
  AF: "Afghanistan",
AL: "Albania",
DZ: "Algeria",
AS: "American Samoa",
AD: "Andorra",
AO: "Angola",
AI: "Anguilla",
AG: "Antigua and Barbuda",
AR: "Argentina",
AM: "Armenia",
AW: "Aruba",
AU: "Australia",
AT: "Austria",
AZ: "Azerbaijan",
BS: "Bahamas",
BH: "Bahrain",
BD: "Bangladesh",
BB: "Barbados",
BY: "Belarus",
BE: "Belgium",
BZ: "Belize",
BJ: "Benin",
BM: "Bermuda",
BT: "Bhutan",
BO: "Bolivia",
BA: "Bosnia and Herzegovina",
BW: "Botswana",
BR: "Brazil",
BN: "Brunei Darussalam",
BG: "Bulgaria",
BF: "Burkina Faso",
BI: "Burundi",
KH: "Cambodia",
CM: "Cameroon",
CA: "Canada",
CV: "Cape Verde",
KY: "Cayman Islands",
CF: "Central African Republic",
TD: "Chad",
CL: "Chile",
CN: "China",
CO: "Colombia",
KM: "Comoros",
CG: "Congo",
CD: "Congo, the Democratic Republic of the",
CK: "Cook Islands",
CR: "Costa Rica",
CI: "Cote D'Ivoire",
HR: "Croatia",
CU: "Cuba",
CY: "Cyprus",
CZ: "Czech Republic",
DK: "Denmark",
DJ: "Djibouti",
DM: "Dominica",
DO: "Dominican Republic",
EC: "Ecuador",
EG: "Egypt",
SV: "El Salvador",
GQ: "Equatorial Guinea",
ER: "Eritrea",
EE: "Estonia",
ET: "Ethiopia",
FK: "Falkland Islands (Malvinas)",
FO: "Faroe Islands",
FJ: "Fiji",
FI: "Finland",
FR: "France",
GF: "French Guiana",
PF: "French Polynesia",
GA: "Gabon",
GM: "Gambia",
GE: "Georgia",
DE: "Germany",
GH: "Ghana",
GI: "Gibraltar",
GR: "Greece",
GL: "Greenland",
GD: "Grenada",
GP: "Guadeloupe",
GU: "Guam",
GT: "Guatemala",
GN: "Guinea",
GW: "Guinea-Bissau",
GY: "Guyana",
HT: "Haiti",
VA: "Holy See (Vatican City State)",
HN: "Honduras",
HK: "Hong Kong",
HU: "Hungary",
IS: "Iceland",
IN: "India",
ID: "Indonesia",
IR: "Iran, Islamic Republic of",
IQ: "Iraq",
IE: "Ireland",
IL: "Israel",
IT: "Italy",
JM: "Jamaica",
JP: "Japan",
JO: "Jordan",
KZ: "Kazakhstan",
KE: "Kenya",
KI: "Kiribati",
KP: "Korea, Democratic People's Republic of",
KR: "Korea, Republic of",
KW: "Kuwait",
KG: "Kyrgyzstan",
LA: "Lao People's Democratic Republic",
LV: "Latvia",
LB: "Lebanon",
LS: "Lesotho",
LR: "Liberia",
LY: "Libyan Arab Jamahiriya",
LI: "Liechtenstein",
LT: "Lithuania",
LU: "Luxembourg",
MO: "Macao",
MK: "Macedonia, the Former Yugoslav Republic of",
MG: "Madagascar",
MW: "Malawi",
MY: "Malaysia",
MV: "Maldives",
ML: "Mali",
MT: "Malta",
MH: "Marshall Islands",
MQ: "Martinique",
MR: "Mauritania",
MU: "Mauritius",
MX: "Mexico",
FM: "Micronesia, Federated States of",
MD: "Moldova, Republic of",
MC: "Monaco",
MN: "Mongolia",
MS: "Montserrat",
MA: "Morocco",
MZ: "Mozambique",
MM: "Myanmar",
NA: "Namibia",
NR: "Nauru",
NP: "Nepal",
NL: "Netherlands",
AN: "Netherlands Antilles",
NC: "New Caledonia",
NZ: "New Zealand",
NI: "Nicaragua",
NE: "Niger",
NG: "Nigeria",
NU: "Niue",
NF: "Norfolk Island",
MP: "Northern Mariana Islands",
NO: "Norway",
OM: "Oman",
PK: "Pakistan",
PW: "Palau",
PA: "Panama",
PG: "Papua New Guinea",
PY: "Paraguay",
PE: "Peru",
PH: "Philippines",
PN: "Pitcairn",
PL: "Poland",
PT: "Portugal",
PR: "Puerto Rico",
QA: "Qatar",
RE: "Reunion",
RO: "Romania",
RU: "Russian Federation",
RW: "Rwanda",
SH: "Saint Helena",
KN: "Saint Kitts and Nevis",
LC: "Saint Lucia",
PM: "Saint Pierre and Miquelon",
VC: "Saint Vincent and the Grenadines",
WS: "Samoa",
SM: "San Marino",
ST: "Sao Tome and Principe",
SA: "Saudi Arabia",
SN: "Senegal",
SC: "Seychelles",
SL: "Sierra Leone",
SG: "Singapore",
SK: "Slovakia",
SI: "Slovenia",
SB: "Solomon Islands",
SO: "Somalia",
ZA: "South Africa",
ES: "Spain",
LK: "Sri Lanka",
SD: "Sudan",
SR: "Suriname",
SJ: "Svalbard and Jan Mayen",
SZ: "Swaziland",
SE: "Sweden",
CH: "Switzerland",
SY: "Syrian Arab Republic",
TW: "Taiwan, Province of China",
TJ: "Tajikistan",
TZ: "Tanzania, United Republic of",
TH: "Thailand",
TG: "Togo",
TK: "Tokelau",
TO: "Tonga",
TT: "Trinidad and Tobago",
TN: "Tunisia",
TR: "Turkey",
TM: "Turkmenistan",
TC: "Turks and Caicos Islands",
TV: "Tuvalu",
UG: "Uganda",
UA: "Ukraine",
AE: "United Arab Emirates",
GB: "United Kingdom",
US: "United States",
UY: "Uruguay",
UZ: "Uzbekistan",
VU: "Vanuatu",
VE: "Venezuela",
VN: "Viet Nam",
VG: "Virgin Islands, British",
VI: "Virgin Islands, U.s.",
WF: "Wallis and Futuna",
EH: "Western Sahara",
YE: "Yemen",
ZM: "Zambia",
ZW: "Zimbabwe",
};

export const LOCAL_STATE = {
  MEX: "MEX",
  DF: "DF"
}

export var isLocalShipping = function(orden,shop) {
  //const state =  getDataShop(shop, shop.addresses ,-1, "state")
    // return (orden.shippingAddress.provinceCode === state)|| (orden.shippingAddress.provinceCode === "MEX");
     return (orden.shippingAddress.provinceCode === LOCAL_STATE.DF )|| (orden.shippingAddress.provinceCode === LOCAL_STATE.MEX);
};
// `connect` returns a new function that accepts the component to wrap:

// We normally do both in one step, like this:
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(General);
